import { DENIED, GRANTED } from "../constants/notifications";

const requestNotificationPermissions = () => {
    if (!window.Notification) {
        return;
    } else if (Notification.permission === GRANTED) {
        return;
    } else if (Notification.permission !== DENIED) {
        Notification.requestPermission();
        return;
    } else {
        return;
    }
}

const hasNotificationsEnabled = () => {
    if (!window.Notification) {
        return false;
    } else if (Notification.permission === GRANTED) {
        return true;
    } else if (Notification.permission !== DENIED) {
        return false;
    } else {
        return false;
    }
}

const sendBrowserNotification = (notificationData) => {
    var notification = new Notification(notificationData.title);
}

export {
    requestNotificationPermissions,
    hasNotificationsEnabled,
    sendBrowserNotification,
};